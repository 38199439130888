import React from 'react'
import classes from "./BlockWithImage.module.scss";
const medicineMacbook = require("../../assets/images/medicineMacbook.png")
const medicineMackbooks = require("../../assets/images/medicineMackbooks.png")
const mallMackbook = require("../../assets/images/mallMackbook.png")
const mallIphone = require("../../assets/images/mallIphone.png")

type Props = {
    imageType:"medicineMacbook"|"medicineMackbooks"|"mallMackbook"|"mallIphone"
}


function BlockWithImage ({imageType}:Props){

    const obj = {
        medicineMacbook,
        medicineMackbooks,
        mallMackbook,
        mallIphone
    }

    return (
        <div className={classes.block_with_image}>
            <img src={obj[imageType]} alt="Design Medicine" />
        </div>
    )
}

export default BlockWithImage