import React, {useState} from "react"
import classes from "./TariffItem.module.scss"
import {Collapse} from "@mui/material";
import CustomButton from "../CustomButton/CustomButton";
import {useNavigate} from "react-router-dom";

type props ={
    btnColor:"white"|"black",
    listTextType:1|2
}
type ListText = {
    title:string,
    text:string
}


function TariffItem({
    btnColor,
    listTextType
}:props){
    const [hide,setHide] = useState(true)
    const navigate = useNavigate()
    const listText:ListText[] = listTextType==1?[
        {
            title:"1. Analysis",
            text:"We analyze the features of projects in your region. We are looking for your advantages in comparison with competitors."
        },
        {
            title:"2. The Technical Assignment (minimum)",
            text:"Choose modules of our proven solution."
        },
        {
            title:"3. Web design (minimum)",
            text:"We add a logo, colors from your brand book. If not, we will help you create a brand book."
        },
        {
            title:"4. Development (minimum)",
            text:"We build your project quickly."
        },
        {
            title:"5. Testing",
            text:"We check all the pages of the site, forms and project settings."
        },
        {
            title:"6. Server hosting",
            text:"Hosted on your own VDS server (do not use shared hosting)."
        },
    ]:[
        {
            title:"1. Analysis",
            text:"We analyze the features of projects in your region. We are looking for your advantages in comparison with competitors."
        },
        {
            title:"2. The Technical Assignment",
            text:"We write a detailed description of your website."
        },
        {
            title:"3. Prototyping",
            text:"We make detailed prototypes with linking. At this stage, the structure of the project is agreed upon."
        },
        {
            title:"4. Web design",
            text:"Development of an individual design for your website."
        },
        {
            title:"5. Development",
            text:"Website page layout. Development of a content management system individually to solve your problems."
        },
        {
            title:"6. Testing",
            text:"We check all the pages of the site, forms and project settings."
        },
        {
            title:"7. Server hosting",
            text:"Hosted on your own VDS server (do not use shared hosting)."
        },
    ]

    return (
        <div className={classes.tariff_item}>
            {listTextType==1?<div className={classes.tariff_item_recommended}>Recommended. Exclusive!</div>:''}
            <h2 className={classes.tariff_item_title}>{listTextType==1?"Faster and cheaper option":"Individual development"}</h2>
            <p className={classes.tariff_item_subtitle}>{listTextType==1?"Work period from 30 days to 60 days":"Work period from 60 days"}</p>
            <Collapse in={!hide} collapsedSize={300}>
                <div className={classes.tariff_item_list}>
                    {listText.map((elem,id)=>(
                        <div className={classes.tariff_item_list_item} key={id}>
                            <p className={classes.tariff_item_list_item_title}>{elem.title}</p>
                            <p className={classes.tariff_item_list_item_text}>{elem.text}</p>
                        </div>
                    ))}
                </div>
            </Collapse>
            <div onClick={()=>setHide(!hide)} className={classes.tariff_item_hide}>{hide?"More":"Hide"}</div>
            <div><CustomButton className={[classes.btn,listTextType==1?classes.btn_black:classes.btn_white].join(' ')} onClick={()=>{navigate('/contacts')}} type={"button"}>Start a project</CustomButton></div>
        </div>
    )
}


export default TariffItem