import React from "react"
import classes from "./Contacts.module.scss";
import FormSendApplication from "../../layouts/Footer/components/Form/Form";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";



function Contacts(){
    return (
        <>
            <Helmet>
                <meta name="description" content="Our contacts. We develop websites individually for the customer."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://vlana.digital/contacts"/>
                <meta property="og:title" content="Contacts - VLANA DIGITAL"/>
                <meta property="og:description" content="Our contacts. We develop websites individually for the customer."/>
                <meta property="og:image" content="https://vlana.digital/images/ogimagelogo.png"/>
            </Helmet>
            <div className={classes.contacts}>
                <div className={classes.contacts_left}>
                    <h2 className={classes.contacts_left_title}>
                        Contacts
                    </h2>
                    <div className={classes.contacts_left_wrap}>
                        <p className={classes.contacts_left_wrap_email_title}>E-mail for cooperation</p>
                        <Link to={"mailto:info@vlana.digital"} className={classes.contacts_left_wrap_email}>info@vlana.digital</Link>
                    </div>
                </div>
                <FormSendApplication  color={"black"} ></FormSendApplication>
            </div>
        </>
    )
}

export default Contacts