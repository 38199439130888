import React, {ReactNode, useEffect, useState} from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import BackgroundImg from "./components/BackgroundImg/BackgroundImg";
import classes from "./WrapperLayout.module.scss";
import Policy from "../Policy/Policy";
import CustomDrawer from "../../components/Drawer/Drawer";
import {useLocation} from "react-router-dom";

type TypeProps = {
    children?:ReactNode | undefined,
    isMainPage?:boolean|undefined,
    noForm?:boolean,
    color?:"black"|"white"|undefined
    policyType:1|2,
    withBack?:boolean
}

function WrapperLayout(props:TypeProps){
    const {children,isMainPage,policyType,color,noForm,withBack} = props
    const [open,setState] = useState(false)
    const { pathname, hash, key } = useLocation();
    useEffect(() => {
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({
                        behavior:"smooth"
                    });
                }
            }, 0);
        }
    }, [pathname, hash, key]); // do this on route change
    return (
        <div className={classes.wrapper}>
            {withBack?<BackgroundImg></BackgroundImg>:''}
            <Header setState={setState} ></Header>
            {children}
            {noForm?"":<Footer isMainPage={isMainPage}></Footer>}
            <Policy color={color} policyType={policyType}></Policy>
            <CustomDrawer open={open} setOpen={setState}></CustomDrawer>
        </div>
    )
}

export default WrapperLayout