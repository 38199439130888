import React, {useState} from "react";
import {Box, Drawer, List, ListItem} from "@mui/material";
import {Link} from "react-router-dom";
import classes from "../../layouts/Header/Header.module.scss";

type Props = {
    open:boolean,
    setOpen:(open:boolean)=>void
}

type TypeArrayLinks = {
    name:string,
    link:string
}

function CustomDrawer ({
    open,
    setOpen
                       }:Props){

    const arrLinks:TypeArrayLinks[] = [
        {
            name:"Home",
            link:"/#home"
        },
        {
            name:"Work",
            link:"/#work"
        },
        {
            name:"Our team",
            link:"/#team"
        },
        {
            name:"Contacts",
            link:"/contacts",
        },
    ];
    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }
                setOpen(false);
            };
    return (
        <Drawer
            anchor={"right"}
            open={open}
            onClose={toggleDrawer(false)}
        >
            <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer( false)}
                onKeyDown={toggleDrawer( false)}
            >
                <List>
                    {arrLinks.map((elem,id)=>{
                        return (
                            <ListItem><Link className={classes.header_right_link} to={elem.link} key={id}>{elem.name}</Link></ListItem>
                        )
                    })}
                </List>
            </Box>
        </Drawer>
    )
}


export default CustomDrawer