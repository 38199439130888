import {RouteObject} from "react-router-dom"
import Main from "../pages/Main/Main";
import WrapperLayout from "../layouts/WrapperLayout/WrapperLayout";
import Medical from "../pages/Medical/Medical";
import Mall from "../pages/Mall/Mall";
import Contacts from "../pages/Contacts/Contacts";
import { Helmet } from "react-helmet"



export const listRoutes:RouteObject[] = [
    {
        path:"/",
        element:<WrapperLayout policyType={1} withBack={true} isMainPage={true}>
            <Main />
        </WrapperLayout>,
    },
    {
        path:"medical",
        element:<WrapperLayout policyType={2}>
            <Medical />
        </WrapperLayout>,
    },
    {
        path:"mall",
        element:<WrapperLayout policyType={2} isMainPage={true}>
            <Mall />
        </WrapperLayout>,
    },
    {
        path:"contacts",
        element:<WrapperLayout policyType={2} noForm={true} color={"white"} isMainPage={true}>

            <title>Contacts - VLANA DIGITAL</title>
            <Contacts />
        </WrapperLayout>,
    },
]