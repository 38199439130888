import React, {useState} from "react"
import classes from "./Footer.module.scss";
import {Form, Link} from "react-router-dom";
import FormSendApplication from "./components/Form/Form";


type FooterProps = {
    isMainPage?:boolean|undefined
}

function Footer({isMainPage}:FooterProps){

    return (
        <footer
            className={classes.footer}
            id={"footer"}
        >
            <div className={classes.footer_left}>
                <h2 className={classes.footer_left_title}>
                    {isMainPage?"Let's start developing the project":"Let's start developing the medicine project"}
                </h2>
                <div className={classes.footer_left_wrap}>
                    <p className={classes.footer_left_wrap_email_title}>E-mail for cooperation</p>
                    <Link to={"mailto:info@vlana.digital"} className={classes.footer_left_wrap_email}>info@vlana.digital</Link>
                </div>
            </div>
            <FormSendApplication color={"white"}></FormSendApplication>
        </footer>
    )
}

export default Footer