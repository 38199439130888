import React from "react"
import classes from "./BackgroundImg.module.scss";
const img = require("../../../../assets/images/background.png")

function BackgroundImg(){
    return (
        <div className={classes.background}>
            <div className={classes.background_img}>

            </div>
        </div>
    )
}

export default BackgroundImg