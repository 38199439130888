import React from 'react';
import './App.css';
import "./commonStyle/index.scss"
import {RouterProvider} from "react-router-dom";
import {router} from "./routes";


function App() {
  return (
    <div className="App">
        <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
