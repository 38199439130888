import React from "react";
import classes from "./Mall.module.scss";
import CustomButton from "../../components/CustomButton/CustomButton";
import TariffItem from "../../components/TariffItem/TariffItem";
import BlockWithImage from "../../components/BlockWithImage/BlockWithImage";
import DesignAndUx from "../../components/DesignAndUx/DesignAndUx";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
const medicineImage = require("../../assets/images/mallImage.png")
const Image = require("../../assets/images/ogimagemalls.png")

function Mall(){
    const navigate = useNavigate()
    return (
        <>
            <Helmet>
                <title>Shopping mall website Design & Development - VLANA DIGITAL</title>
                <meta name="description" content="Our solutions have been proven by more than 3 years of experience. Shopping mall website development, design, digital marketing."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://vlana.digital/mall"/>
                <meta property="og:title" content="Shopping mall website Design & Development - VLANA DIGITAL"/>
                <meta property="description" content="Our solutions have been proven by more than 3 years of experience. Shopping mall website development, design, digital marketing." />
                <meta property="og:image" content="https://vlana.digital/images/ogimagemalls.png" />
            </Helmet>
            <div
                className={classes.medical}
            >
                <div className={classes.medical_top_info}>
                    <div className={classes.medical_top_info_left}>
                        <h2 className={classes.medical_top_info_left_title}>Shopping mall website
                            <br /> development and desing</h2>
                        <p className={classes.medical_top_info_left_subtitle}>Projects focused on conversion and SEO optimization</p>
                        <div className={classes.medical_top_info_left_btn_wrap}>
                            <CustomButton
                                className={classes.medical_top_info_left_btn}
                                onClick={()=>{navigate("/contacts")}}
                                type={"button"}>
                                Start a project
                            </CustomButton>
                        </div>
                        <div className={classes.medical_top_info_left_description}>
                            We've worked with 2 websites in <br /> the field of shopping malls. Our<br /> solutions have been proven by<br /> more than 3 years of experience.
                        </div>
                    </div>
                    <div className={classes.medical_top_info_right}>
                        <img src={medicineImage} alt="Medicine main image" />
                    </div>
                </div>
                <DesignAndUx disignType="mall"></DesignAndUx>
                <div className={classes.medical_block_images}>
                    <BlockWithImage imageType={"mallMackbook"}></BlockWithImage>
                    <BlockWithImage imageType={"mallIphone"}></BlockWithImage>
                </div>
                <div className={classes.medical_seo}>
                    <div className={classes.medical_seo_header}>
                        <h2 className={classes.medical_seo_title}>SEO</h2>
                        <p className={classes.medical_seo_text}>High page loading speed.<br/>
                            All SEO settings.<br/>
                            Scheme and OpenGraph markup.</p>
                    </div>
                    <div className={classes.medical_seo_wrap}>
                        <div></div>
                        <div>
                            <div className={classes.medical_seo_quantity}>6 319 <p>
                                Launch of a <br/> new website
                            </p></div>
                            <div className={classes.medical_seo_quantity}>6 041 <p>
                                January -  <br/> July 2023
                            </p></div>
                        </div>
                    </div>
                </div>
                <div className={classes.medical_how_working}>
                    <div className={classes.medical_how_working_wrap}>
                        <h2 className={classes.medical_how_working_title}>How we’re working</h2>
                        <div className={classes.medical_how_working_tariffs}>
                            <TariffItem
                                btnColor={"black"}
                                listTextType={1}
                            ></TariffItem>
                            <TariffItem
                                btnColor={"white"}
                                listTextType={2}
                            ></TariffItem>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Mall