import React from "react"
import classes from "./Policy.module.scss"
import {Link} from "react-router-dom";
import cookie from "../../assets/docs/cookie_policy.pdf"
import privacy from "../../assets/docs/privacy.pdf"

type TypeArrayLinks = {
    name:string,
    link:string,
    isLink:boolean,
    target?:string
}
type Props = {
    policyType?:1|2,
    color:"black"|"white"|undefined

}
function Policy({policyType,color="black"}:Props){

    const arrLinks:TypeArrayLinks[] = policyType==1?[
        {
            name:"Cookie Policy",
            link:cookie,
            target:"_blank",
            isLink:true
        },
        {
            name:"Privacy Policy",
            link:privacy,
            target:"_blank",
            isLink:true
        },
        {
            name:"2023. ALL RIGHTS RESERVED",
            link:"/",
            isLink:false
        },
    ]:[
        {
            name:"",
            link:"/",
            isLink:false
        },
        {
            name:"2023. ALL RIGHTS RESERVED",
            link:"/",
            isLink:false
        },
    ];

    return (
        <div  className={[classes.policy,(color && color=="white")?classes.white:''].join(' ')} >
            <div className={policyType?classes.policy_wrap:classes.policy_wrap1}>
                <Link to={'/'} className={classes.footer_left}>
                    <h2 className={classes.policy_left_title}>Vlana</h2>
                    <p className={classes.policy_left_subtitle}>DIGITAL</p>
                </Link>
                <div className={classes.policy_right}>
                    {arrLinks.map((elem,id)=>{
                        return (
                            elem.isLink
                                ?<a target={elem.link} className={classes.policy_right_link} href={elem.link} key={id}>{elem.name}</a>
                                :<div className={classes.policy_right_copy} key={id}>{elem.name}</div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Policy