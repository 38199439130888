import React, {useState} from 'react'
import classes from "./TextWithDropDownImage.module.scss";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import {useNavigate} from "react-router-dom";
const app = require("../../../../assets/images/app.jpg")
const hand = require("../../../../assets/images/hand.jpg")
const circle = require("../../../../assets/images/digital-circle.jpg")
const html = require("../../../../assets/images/html.jpg")

const objImage = {
    app:app,
    hand:hand,
    circle:circle,
    html:html
}

type Props = {
    image:"app"|"hand"|"circle"|"html",
    text:string
}

function TextWithDropDownImage(props:Props){
    const {image,text} = props
    const navigate = useNavigate()

    return (
        <div className={classes.text_drop_down}>
            <div className={classes.text_drop_down_none_active}>{text}</div>
            <div className={classes.text_drop_down_active}
                style={{background: `center/cover no-repeat url(${objImage[image]})`}}>
                <p className={classes.text_drop_down_active_text}>{text}</p>
                <CustomButton
                    className={classes.text_drop_down_active_btn}
                    onClick={() => {
                        navigate("#footer")
                    }}
                    type={"button"}
                >
                    Start a project
                </CustomButton>
            </div>
        </div>
    )
}

export default TextWithDropDownImage