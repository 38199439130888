import React, {ReactNode} from "react"
import {styled, TextField} from "@mui/material";

type onChange = (event:React.ChangeEvent<HTMLInputElement>)=>void
type InputProps = {
    label?:ReactNode | undefined,
    className?:string,
    value:string,
    onChange:onChange,
    name:string,
    height?:number|82,
    multiline?:boolean
    error?:boolean
}


const CssTextField = styled(TextField)({
    '& label': {
        color: "#FFF",
        fontSize:"24px",
        fontStyle:"normal",
        fontWeight:400,
        lineHeight:"140%"
    },
    '& label.Mui-focused': {
        color: "#FFF",
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& .MuiFormControl-root':{
        height:"82px"
    },
    '& .MuiOutlinedInput-root': {
        '& input':{
            padding:"24px",
            height:`82px`,
            boxSizing:"border-box",
            color:"#fff",
            fontSize:"24px",
            fontStyle:"normal",
            fontWeight:400,
            lineHeight:"140%"
        },
        '& textarea':{
            padding:"24px",
            boxSizing:"border-box",
            color:"#fff",
            fontStyle:"normal",
            fontWeight:400,
            fontSize:"24px"

        },
        '& fieldset': {
            borderColor: '#E0E3E7',
            borderRadius: "15px",
        },
        '&:hover fieldset': {
            borderColor: '#B2BAC2',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6F7E8C',
        },
    },
});
function Input({
    label,
    className,
    value,
    onChange,
    name,
    height=82,
    multiline,
    error
               }:InputProps){

    return (
        <CssTextField
            style={{height:`${height}px`}}
            label={label}
            value={value}
            onChange={onChange}
            className={className}
            multiline={multiline}
            rows={3}
            maxRows={8}
            error={error}
        ></CssTextField>
    )
}

export default Input