import React, {useEffect, useRef, useState} from "react"
import {Swiper, SwiperRef, SwiperSlide} from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import Graphic from "../Graphic/Graphic";
import {Swiper as SwiperType} from "swiper/types";
import "./SwiperGraphic.scss"

type Props = {
    className?:string
}

function SwiperGraphic({
    className
}:Props){
    const [swiper,setSwiper] = useState<SwiperType>()

    const cls = []
    if(className)cls.push(className)




    return (
        <div className={"swiper"}>
            <Swiper
                modules={[Navigation,Pagination]}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => setSwiper(swiper)}
                navigation={{
                    prevEl: '.swiper-prev',
                    nextEl: '.swiper-next',
                }}
                pagination={{
                    type:'bullets'
                }}
            >
                <SwiperSlide>
                    <div className={"slide_wrap"}>
                        <Graphic title={"Traffic from google 2022-2023"}></Graphic>
                        <div className={"graphic_img"}></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"slide_wrap"}>
                        <Graphic typeCharts={2} title={"Traffic from google 2020-2022"}></Graphic>
                        <div className={"graphic_img_2"}></div>
                    </div>
                </SwiperSlide>
            </Swiper>
            <div className={"swiper_navigation"}>
                <div className="swiper-prev" >
                </div>
                <div className="swiper-next">
                </div>
            </div>
        </div>
    )
}

export default SwiperGraphic