import React from "react"
import classes from "./OurTeam.module.scss";


function OurTeam (){
    return (
        <div className={classes.our_team}>
            <h2 className={classes.our_team_title}>Our team</h2>
            <div className={classes.our_team_right}>
                <p className={classes.our_team_right_text}>Developers with over 5 years experience. Our business<br /> location is Kazakhstan.</p>
                <div className={classes.our_team_right_wrap}>
                    <div className={classes.our_team_right_dev}>
                        <p className={classes.our_team_right_dev_number}>7</p>
                        <p className={classes.our_team_right_dev_role}>Backend <br/> Developers</p>
                    </div>
                    <div className={classes.our_team_right_dev}>
                        <p className={classes.our_team_right_dev_number}>5</p>
                        <p className={classes.our_team_right_dev_role}>Frontend <br/> Developers</p>
                    </div>
                    <div className={classes.our_team_right_dev}>
                        <p className={classes.our_team_right_dev_number}>4</p>
                        <p className={classes.our_team_right_dev_role}>Web <br/> Designers</p>
                    </div>
                </div>
                <div className={classes.our_team_right_info} >And also project managers, testers, digital marketers and our friends.</div>
            </div>
        </div>
    )
}
export default OurTeam