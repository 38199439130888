import React, {useEffect, useMemo} from "react"
import classes from "./Graphic.module.scss";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

import moment from "moment";
import CustomBar from "./Components/CustomBar/CustomBar";


type Props = {
    title:string,
    typeCharts?:1|2
}
type coordinates = { SEO:number }

const arrAmount:coordinates [] = [
    {SEO:0},
    {SEO:4138},
    {SEO:4074},
    {SEO:5882},
    {SEO:11345},
    {SEO:19177},
    {SEO:33432},
    {SEO:71693},
    {SEO:87562},
    {SEO:91169},
    {SEO:114858},
    {SEO:119030},
    {SEO:132002},
]
const arrAmount2:coordinates [] = [
    {SEO:0},
    {SEO:240},
    {SEO:246},
    {SEO:304},
    {SEO:1146},
    {SEO:4279},
    {SEO:11587
    },
    {SEO:18856
    },
    {SEO:24846
    },
    {SEO:38137
    },
    {SEO:28601
    },
    {SEO:64136
    },
    {SEO:75833
    },
    {SEO:75253
    },
    {SEO:108008
    },
    {SEO:102094
    },
    {SEO:72945
    },
    {SEO:88352
    },
    {SEO:72503
    }
]

interface data extends coordinates{
    barMonth:string,
}



function Graphic ({title,typeCharts=1}:Props){
    const data:data[] = useMemo(()=>{
        let arr: data[] =[]
        if(typeCharts == 1){
            for(let i = 1; i<=12;i++){
                arr.push({
                    barMonth:moment(`2.${i}.2022`,"DD.MM.YYYY").format("MMM, YYYY"),
                    SEO:arrAmount[i].SEO,
                })
            }
        }else{
            for(let i = 1; i<=12;i++){
                arr.push({
                    barMonth:moment(`2.${i}.2020`,"DD.MM.YYYY").format("MMM, YYYY"),
                    SEO:arrAmount2[i].SEO,
                })
            }
            for(let i = 1; i<=6;i++){
                arr.push({
                    barMonth:moment(`2.${i}.2021`,"DD.MM.YYYY").format("MMM, YYYY"),
                    SEO:arrAmount2[12+i].SEO,
                })
            }
        }

        return arr
    },[])


    return (
        <div className={classes.graphic}>
            <h2 className={classes.graphic_title}>{title}</h2>
            <div className={classes.graphic_wrap}>
                <BarChart
                    width={1200}
                    height={500}
                    className={classes.barchart}
                    data={data}

                >
                    <CartesianGrid
                        vertical={false}
                        stroke={"#5A5A5A"}
                        strokeDasharray="3 3"
                    />
                    <XAxis
                        fontFamily="Space Grotesk"
                        stroke={"#000"}
                        opacity={3}
                        className={classes.charts_x}
                        dataKey="barMonth"
                        ticks={data.map(elem=>elem.barMonth)}
                        tickCount={data.length}
                        interval={0}
                        tick={(props)=> {
                            const value = props.payload.value.split(", ")
                            return (
                                <text fontFamily="Space Grotesk" orientation="left" width="60" height="44"
                                      stroke="none" x={props.x} y={props.y}
                                      text-anchor="end"
                                      fill="black">
                                    <tspan className={typeCharts==1?classes.charts_x:classes.charts_x2} x={typeCharts==1?props.x +30:props.x+10} dy=".1em"
                                           y={props.y + 24}>{value[0]},</tspan>
                                    <tspan className={typeCharts==1?classes.charts_x:classes.charts_x2} x={typeCharts==1?props.x +30:props.x+15} dy=".1em"
                                           y={props.y + 54}>{value[1]}</tspan>
                                </text>
                        )}} />
                    <YAxis
                        type="number"
                        ticks={typeCharts==1?[0,20000,40000,60000,80000,100000,120000, 140000]:
                            [0,20000,40000,60000,80000,100000,120000]
                        }
                        stroke={"none"}
                        tickMargin={10}
                        allowDataOverflow={true}
                        tick={(props)=> {
                            let value = props.payload.value.toString().split('')
                            let text = ""
                            if(value.length>1) {
                                value.splice(value.length - 3, 3)
                                text =`${value.join('')}k`
                            }else{
                                text="0"
                            }
                            return(
                                <text fontFamily="Space Grotesk" orientation="left" width="60" height="460"
                                      stroke="none" x="57" y={props.y}
                                      text-anchor="end"
                                      fill="black">
                                    <tspan className={classes.charts_y} x={props.x}
                                           y={props.y + 5}>{text}</tspan>
                                </text>

                            )
                        }} />
                    <Tooltip></Tooltip>
                    <Bar
                        className={classes.bar}
                        label={props => {
                            let value = props.value.toString().split('')
                            let text = ""
                            if(value.length>1 && value.length>4) {
                                value.splice(value.length - 3, 3)
                                text =`${value.join('')}k`
                            }else{
                                text=value.join('')
                            }
                            return (
                                <text fontFamily="Space Grotesk" orientation="left" width="60" height="460"
                                      stroke="none" x="57" y={props.y}
                                      text-anchor="end"
                                      fill="black">
                                    <tspan className={classes.charts_y} x={props.x+45}
                                           y={props.y -10}>{text}</tspan>
                                </text>
                            )
                        }}
                        barSize={20}
                        dataKey="SEO"
                        shape={(props)=><CustomBar typeCharts={typeCharts} fill={props.fill} x={props.x} y={props.y} width={props.width} height={props.height} />}
                    ></Bar>
                </BarChart>
            </div>
        </div>
    )
}

export default Graphic