import React, {ReactNode, SyntheticEvent} from 'react'
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, styled} from "@mui/material";

type Props = {
    label?:ReactNode,
    className?:string,
    error?:boolean,
    onChange:(event: SyntheticEvent<Element, Event>, checked: boolean) => void,
    checked:boolean
}
const CSSCheckbox = styled(Checkbox)({
    padding:"4px",
    '& svg':{
        border:"1px solid #5A5A5A",
        borderRadius: "4px"
    }
})

function CustomCheckbox({label,className,error,onChange,checked}:Props){
    return (
        <FormControl>
            <FormControlLabel onChange={onChange} checked={checked}  className={className} required control={<CSSCheckbox />} label={label} />
            {error?<FormHelperText style={{color:"#FF0000"}}>You must accept Terms Of Service to continue</FormHelperText>:''}
        </FormControl>
    )
}

export default CustomCheckbox