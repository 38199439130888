import React from "react"
import classes from "./DesignAndUx.module.scss";
import CustomButton from "../CustomButton/CustomButton";
import {useNavigate} from "react-router-dom";
const medicineImage = require("../../assets/images/designPhone.png")

type props = {
    disignType?:"mall"|"medicine"
}
function DesignAndUx({
    disignType="medicine"
}:props){
    const navigate = useNavigate()
    return (
        <div className={[disignType==="medicine"?classes.design_medicine:classes.design_mall,classes.design].join(' ')}>
            <div className={classes.design_left}>
                <div>
                    <h2 className={classes.design_left_title}>{
                        disignType==="medicine"?"Design and UX":<>Interactive floor plan <br/> of the mall.<br/>
                            Convenient interface<br/> and filters.</>
                    }</h2>
                    {disignType==="medicine"?<div className={classes.design_left_text}>
                        The goal of the project was to study conversion issues and improve customer interaction with the
                        site by updating the structure and user experience. It was necessary to reflect as much as
                        possible the proposed list of services provided by the clinic in order to help users learn more
                        about medicine and speed up the doctor's call or appointment.
                    </div>:""}
                </div>
                <div className={classes.design_left_btn_wrap}><CustomButton className={classes.design_left_btn} onClick={()=>{navigate("#footer")}} type={"button"}>Get a presentation</CustomButton></div>

            </div>
            <div className={[disignType==="medicine"?classes.design_medicine:classes.design_mall,classes.design_img_mobile].join(' ')}></div>

        </div>
    )
}

export default DesignAndUx