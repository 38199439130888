import React from "react";
import classes from "./Header.module.scss"
import {Link} from "react-router-dom";
import {IconButton} from "@mui/material";
import { ReactComponent as Menu } from "../../assets/icons/menu.svg"

type TypeArrayLinks = {
    name:string,
    link:string,

}
type Props = {
    setState:(open:boolean)=>void

}
function Header({setState}:Props){

    const arrLinks:TypeArrayLinks[] = [
        {
            name:"Home",
            link:"/#home"
        },
        {
            name:"Work",
            link:"/#work"
        },
        {
            name:"Our team",
            link:"/#team"
        },
        {
            name:"Contacts",
            link:"/contacts",
        },
    ];


    return (
        <div className={classes.header}>
            <Link to={'/'} className={classes.header_left}>
                <h2 className={classes.header_left_title}>Vlana</h2>
                <p className={classes.header_left_subtitle}>DIGITAL</p>
            </Link>
            <div className={classes.header_right}>
                {arrLinks.map((elem,id)=>{
                    return (
                        <Link className={classes.header_right_link} to={elem.link} key={id}>{elem.name}</Link>
                    )
                })}
            </div>
            <div className={classes.header_right_mobile}>
                <IconButton onClick={()=>setState(true)}><Menu /></IconButton>
            </div>
        </div>
    )
}


export default Header