

type Props = {
    fill:string, x:number, y:number, width:number, height:number,typeCharts:1|2
}

function CustomBar({
   fill, x, y, width, height,typeCharts
}:Props){
    return (
        <g x={x} y={500}>
            <line x1={typeCharts==1?x+50:x+20} y1={466} x2={typeCharts==1?x+50:x+20} y2={y} d={`M${x} ${height}L${x} 0`} stroke="black"/>
            <circle cx={typeCharts==1?x+50:x+20} cy={y} r="6" fill="white" stroke="black" stroke-width="2"/>
        </g>

    )
}

export default CustomBar