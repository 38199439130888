import React from 'react'
import classes from "./Medical.module.scss"
import CustomButton from "../../components/CustomButton/CustomButton";
import DesignAndUx from "../../components/DesignAndUx/DesignAndUx";
import BlockWithImage from "../../components/BlockWithImage/BlockWithImage";
import Graphic from "./components/Graphic/Graphic";
import SwiperGraphic from "./components/SwiperGraphic/SwiperGraphic";
import OurTeam from "../../components/OurTeam/OurTeam";
import TariffItem from "../../components/TariffItem/TariffItem";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
const medicineImage = require("../../assets/images/medicine.png")

function Medical(){
    const navigate = useNavigate()
    return (
        <>
            <Helmet>
                <title>Medical, healthcare, clinics websites Design & Development - VLANA DIGITAL</title>
                <meta name="description" content="Our solutions have been tested by more than 5 years of experience. Medical, healthcare, clinics websites design, development, digital marketing." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://vlana.digital/medical" />
                <meta property="og:title" content="Medical, healthcare, clinics websites Design & Development - VLANA DIGITAL" />
                <meta property="og:description" content="Our solutions have been tested by more than 5 years of experience. Medical, healthcare, clinics websites design, development, digital marketing." />
                <meta property="og:image" content="https://vlana.digital/images/ogimagemed.png" />
            </Helmet>
            <div
                className={classes.medical}
            >
                <div className={classes.medical_top_info}>
                    <div className={classes.medical_top_info_left}>
                        <h2 className={classes.medical_top_info_left_title}>Medical website design <br /> and development</h2>
                        <p className={classes.medical_top_info_left_subtitle}>Projects focused on conversion and SEO optimization</p>
                        <div className={classes.medical_top_info_left_btn_wrap}>
                            <CustomButton
                                className={classes.medical_top_info_left_btn}
                                onClick={()=>{navigate("/contacts")}}
                                type={"button"}>
                                Start a project
                            </CustomButton>
                        </div>
                        <div className={classes.medical_top_info_left_description}>
                            We’ve been working on 5 websites*<br/> in the field of medicine. Our<br/> solutions have been tested by more<br/> than 5 years of experience.
                        </div>
                    </div>
                    <div className={classes.medical_top_info_right}>
                        <img src={medicineImage} alt="Medicine main image" />
                    </div>
                </div>
                <DesignAndUx></DesignAndUx>
                <div className={classes.medical_block_images}>
                    <BlockWithImage imageType={"medicineMacbook"}></BlockWithImage>
                    <BlockWithImage imageType={"medicineMackbooks"}></BlockWithImage>
                </div>
                <div className={classes.medical_seo}>
                    <div className={classes.medical_seo_header}>
                        <h2 className={classes.medical_seo_title}>SEO</h2>
                        <div className={classes.medical_seo_text}>High page loading speed.<br/>
                            All SEO settings.<br/>
                            Scheme and OpenGraph markup.</div>
                    </div>
                    <div className={classes.medical_swiper}>
                        <SwiperGraphic></SwiperGraphic>
                    </div>
                </div>
                <div className={classes.medical_how_working}>
                    <div className={classes.medical_how_working_wrap}>
                        <h2 className={classes.medical_how_working_title}>How we’re working</h2>
                        <div className={classes.medical_how_working_tariffs}>
                            <TariffItem
                                btnColor={"black"}
                                listTextType={1}
                            ></TariffItem>
                            <TariffItem
                                btnColor={"white"}
                                listTextType={2}
                            ></TariffItem>
                        </div>
                    </div>
                </div>
                <div className={[classes.medical_how_working,classes.mb_200].join(' ')}>
                    <OurTeam></OurTeam>
                </div>
            </div>
        </>

    )
}


export default Medical
