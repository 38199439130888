import {Box, Modal, Typography} from "@mui/material";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#FFF',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius:"16px",
    textAlign:"center"
};

type Props = {
    open:boolean,
    handleClose:()=>void
}


export default function BasicModal({open,handleClose}:Props) {

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Your application has been successfully created
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
}