import React from "react"
import classes from "./ExpertiseItem.module.scss";
import {Link} from "react-router-dom";
const medicalImg = require("../../../../assets/images/macbook.png")
const shoppingImg = require("../../../../assets/images/macbook-sale.png")


const imageObj = {
    medical:medicalImg,
    shoppingMall:shoppingImg
}

export type ExpertiseProps = {
    title:string,
    text:string,
    img:"medical"|"shoppingMall",
    to:string
}

function ExpertiseItem ({text,title,img,to}:ExpertiseProps){
    return (
        <div className={classes.expertise_item}>
            <Link to={to} className={classes.expertise_item_title}>{title}</Link>
            <p className={classes.expertise_item_text}>{text}</p>
            <div className={classes.expertise_item_img}>
                <img src={imageObj[img]} alt="ExpertiseImage"/>
            </div>
        </div>
    )
}

export default ExpertiseItem