import React from "react"
import classes from "./Technologies.module.scss"
import photoshop from "../../../../assets/icons/photoshop.svg"
import ai from "../../../../assets/icons/ai.svg"
import figma from "../../../../assets/icons/figma.svg"
import react from "../../../../assets/icons/react.svg"
import github from "../../../../assets/icons/github.svg"
import confluence from  "../../../../assets/icons/confluence.svg"
import rabbit from "../../../../assets/icons/rabbit.svg"
import googleAdds from "../../../../assets/icons/googleAdds.svg"
import php from "../../../../assets/icons/php.svg"
import MySql from "../../../../assets/icons/MySql.svg"
import zend from "../../../../assets/icons/zend.svg"
import nginx from "../../../../assets/icons/nginx.svg"
import postgresql from "../../../../assets/icons/postgresql.svg"
import redis from "../../../../assets/icons/redis.svg"
import binks from "../../../../assets/icons/binks.svg"
import node from "../../../../assets/icons/node.svg"
import redux from "../../../../assets/icons/redux.svg"
import yii from "../../../../assets/icons/yii.svg"
import jira from "../../../../assets/icons/jira.svg"
import trello from "../../../../assets/icons/trello.svg"
import elasticsearch from "../../../../assets/icons/elasticsearch.svg"
import docker from "../../../../assets/icons/docker.svg"
import typescript from "../../../../assets/icons/typescript.svg"
import javascript from "../../../../assets/icons/javascript.svg"
import html from "../../../../assets/icons/html.svg"
import css from "../../../../assets/icons/css.svg"
import etc from "../../../../assets/icons/etc.svg"
import CustomButton from "../../../../components/CustomButton/CustomButton";
import {useNavigate} from "react-router-dom";

const arrTechnologies = [
    photoshop,ai,figma,react,github,confluence,rabbit,googleAdds,php,MySql,zend,nginx,postgresql,redis,binks,node,redux,yii,jira,trello,elasticsearch,docker,typescript,javascript,html,css,etc
]

function Technologies(){
    const navigate = useNavigate()
    return (
        <div className={classes.technologies}>
            <h2 className={classes.technologies_title}>Technologies</h2>
            <div className={classes.technologies_right}>
                <p className={classes.technologies_right_text}>We use advanced technologies in various areas <br/> of our business.</p>
                <div className={classes.technologies_right_icons}>
                    {arrTechnologies.map((elem,id)=>{
                        return (
                            <div className={classes.technologies_right_icon_wrap} key={id}>
                                <div className={classes.technologies_right_icon} style={{backgroundImage:`url(${elem})`}} ></div>
                            </div>
                        )
                    })}
                </div>
                <CustomButton className={classes.technologies_btn} onClick={()=>{navigate("contacts")}} type={"button"}>
                    <div className={classes.technologies_btn_wrap}>
                        Have a website that needs to be improved?<br/> Let's get started
                    </div>
                    <div className={classes.technologies_btn_icon}></div>
                </CustomButton>
            </div>
        </div>
    )
}

export default Technologies