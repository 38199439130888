import React, {ReactNode} from "react";
import {Button, styled} from "@mui/material";

export type ButtonProps = {
    className?:string,
    onClick:React.MouseEventHandler<HTMLButtonElement>;
    children?:ReactNode | undefined,
    type:"button" | "submit" | "reset" | undefined
}
const CssButton = styled(Button)({
    borderRadius:"15px",
    border: "1px solid #5A5A5A",
    padding:"24px",
    boxSizing:"border-box",
    justifyContent:"flex-start",
    alignItems:"center",
    textTransform:"none",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "400" ,
    lineHeight: "140%",
    fontFamily:" Space Grotesk"
});

function CustomButton({className,onClick,children,type}:ButtonProps){
    return (
        <CssButton
            className={className}
            onClick={onClick}
            type={type}
        >{children}</CssButton>
    )
}

export default CustomButton