import React, {useState} from "react"
import classes from "./Fom.module.scss"
import Input from "../../../../components/Input/Input";
import ButtonLoadFile from "../../../../components/ButtonLoadFile/ButtonLoadFile";
import {Form, Link} from "react-router-dom";
import CustomCheckbox from "../../../../components/CustomCheckbox/CustomCheckbox";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import axiosFiber, {axiosErrorHandler} from "../../../../axios/axiosFiber";
import request, {AxiosError} from "axios";
import BasicModal from "../../../../components/Modal/Modal";
import privacy from "../../../../assets/docs/privacy.pdf"

type Props ={
    color:"black"|"white"
}
type ErrorsObject = {
    error:string,
    field:string,
}
interface ExtendedAxiosError {
    response:{
        data:{
            errors:ErrorsObject[]
        }
    }

}
function FormSendApplication({color}:Props){
    const [errors,setErrors] = useState<ErrorsObject[]>([])
    const [name,setName] = useState("")
    const [phone,setPhone] = useState("")
    const [email,setEmail] = useState("")
    const [company,setCompany] = useState('')
    const [file,setFile] = useState<File>()
    const [massage,setMassage] = useState('')
    const [acceptTerms,seAcceptTerms] = useState<1|0>(0)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    async function SendData (){
        let formData:any = new FormData()
        formData.append("name",name)
        formData.append("phone",phone)
        formData.append("email",email)
        formData.append("company",company)
        formData.append("file",file)
        formData.append("massage",massage)
        formData.append("acceptTerms",acceptTerms.toString())



        try {
            const response = await axiosFiber.post("",formData)
            setErrors([])
            handleOpen()
        }catch (e) {
            let error = e as ExtendedAxiosError
            if(error.response?.data){
                let validationObg = error.response.data.errors
                setErrors(validationObg)
            }
        }
    }


    return (
        <Form className={classes.form} method="post">
            <div className={classes.form_form}>
                <Input error={Boolean(errors.find(elem=>elem.field==="name"))} className={color=="black"?classes.black:''} name="name" label="Name*" value={name} onChange={(event)=>{setName(event.target.value)}}></Input>
                <Input className={color=="black"?classes.black:''} name="phone"  label="Phone" value={phone} onChange={(event)=>{setPhone(event.target.value)}}></Input>
                <Input error={Boolean(errors.find(elem=>elem.field==="email"))} className={color=="black"?classes.black:''} name="email"  label="E-mail*" value={email} onChange={(event)=>{setEmail(event.target.value)}}></Input>
                <Input className={color=="black"?classes.black:''} name="company"  label="Company" value={company} onChange={(event)=>{setCompany(event.target.value)}}></Input>
                <ButtonLoadFile onChange={(file)=>setFile(file)}  className={[classes.form_btn,color=="black"?classes.black:''].join(' ')} label={
                    <div className={color=="black"?classes.form_file_black:classes.form_file}>
                        File <span>(Have a brief or presentation? Submit!)</span>
                    </div>
                }></ButtonLoadFile>
                <Input multiline  height={169} className={[classes.form_btn,color=="black"?classes.black:''].join(' ')} name="Message"  label="Message" value={massage} onChange={(event)=>{setMassage(event.target.value)}}></Input>
            </div>
            <div className={classes.form_checkbox}>
                <CustomCheckbox
                    className={color=="black"?classes.form_checkbox_black:classes.form_checkbox_label}
                    label={
                        <div className={classes.form_label}>
                            By clicking on the «Send message» button, I accept the booking conditions and agree to the <a target="_blank" href={privacy}  className={color=="black"?classes.form_link_blue:classes.form_link}>personal data processing policy</a>.
                        </div>
                    }
                    checked={Boolean(acceptTerms)}
                    onChange={(event)=>seAcceptTerms(acceptTerms==1?0:1)}
                    error={Boolean(errors.find(elem=>elem.field==="acceptTerms"))}
                ></CustomCheckbox>
            </div>
            <CustomButton
                className={color=="black"?classes.button_white:classes.button}
                onClick={()=>SendData()}
                type={"button"}
            >
                Send message
            </CustomButton>
            <BasicModal handleClose={handleClose} open={open}></BasicModal>
        </Form>
    )
}


export default FormSendApplication