import React, {ReactNode} from "react"
import {Button, styled} from "@mui/material";
import convertFileToData from "../../funtions/converFileToData";
import dataURItoBlob from "../../funtions/dataUriToBlob";




export type Props = {
    label?:ReactNode,
    className:string,
    onChange:(file:File | undefined)=>void
}
const CssButton = styled(Button)({
    borderRadius:"15px",
    border: "1px solid #5A5A5A",
    padding:"24px",
    boxSizing:"border-box",
    justifyContent:"flex-start",
    alignItems:"center",
});


const VisuallyHiddenInput = styled('input')({
    opacity: '0',
    overflow: 'hidden',
    position: 'absolute',
    bottom: "0px",
    left: "0px",
    right:"0px",
    top:"0px",
    zIndex:10,
    whiteSpace: 'nowrap',
});


function ButtonLoadFile({
    label,
    className,
    onChange
}:Props){

    async function  AddNewFile (event:React.FormEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement & {
            files:FileList
        }
        let file:File = target.files[0]
        onChange(file)
        // let fileData = await convertFileToData(file)
        // const blob = dataURItoBlob(fileData,file.type);


    }

    return (
        <CssButton
            className={className}
        >
            {label?label:"Upload File"}
            <VisuallyHiddenInput onInput={AddNewFile} type="file"></VisuallyHiddenInput>
        </CssButton>
    )
}

export default ButtonLoadFile