import React, {ReactElement, ReactNode, useEffect} from "react";
import classes from "./Main.module.scss";
import TextWithDropDownImage from "./components/TextWithDropDownImage/TextWithDropDownImage";
import ExpertiseItem from "./components/ExperticeItem/ExpertiseItem";
import OurTeam from "../../components/OurTeam/OurTeam";
import Technologies from "./components/Technologies/Technologies";
import CustomButton from "../../components/CustomButton/CustomButton";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";

type TypeProps = {
    children?:ReactNode | undefined
}
type service = {
    text:string,
    image:"app"|"hand"|"circle"|"html",
}

const arrServices:service[] = [
    {
        text:"Creative Web-design",
        image:"circle"
    },
    {
        text:"Website development",
        image:"html"
    },
    {
        text:"Digital marketing",
        image:"hand"
    },
    {
        text:"Mobile APPs",
        image:"app"
    }
]


function Main(props:TypeProps):ReactElement{

    const navigate = useNavigate()


    return (
        <>
            <Helmet

            >

            </Helmet>
            <div className={classes.main} id={"home"}>
                <div className={classes.main_top_info} >
                    <h2 className={classes.main_top_info_title}>Team for your website</h2>
                    <div className={classes.main_top_info_wrp}>
                        <CustomButton onClick={()=>{navigate("#footer")}} type={"button"} className={classes.black_button}>
                            Start a project
                        </CustomButton>
                        <CustomButton onClick={()=>{navigate("/#work")}} type={"button"} className={classes.white_button}>
                            Our expertise
                        </CustomButton>
                    </div>
                </div>
                <div className={classes.main_info}>
                    <div className={classes.main_info_wrap}>
                        <h2 className={classes.main_info_title}>Services</h2>
                        <div className={classes.main_services}>
                            {arrServices.map((elem,id)=>(
                                <TextWithDropDownImage image={elem.image} text={elem.text} key={id}></TextWithDropDownImage>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={classes.black_wrap}>
                    <div className={classes.black_wrap_top_info}>
                        We develop websites <span>individually</span> for the customer. No open source CMS, no publicly available templates.
                        <span> Only our proven solutions.</span>
                    </div>
                    <CustomButton onClick={()=>{navigate("#footer")}} type={"button"} className={classes.black_button_with_border}>
                        Start a project
                    </CustomButton>
                    <div className={classes.expertise} id={"work"}>
                        <div className={classes.expertise_wrap}>
                            <h2 className={classes.expertise_title}>Expertise</h2>
                            <p className={classes.expertise_text}>We have developed projects in more than 20 business niches. We have highlighted the main highlights.</p>
                            <ExpertiseItem to={"medical"} title={"Medical healthcare Clinics websites"} text={"Projects focused on conversion and SEO optimization"} img={"medical"} />
                            <ExpertiseItem to={"mall"} title={"Shopping mall website development and desing"} text={"Projects focused on convenience and SEO optimization"} img={"shoppingMall"} />
                        </div>
                        <CustomButton className={classes.expertise_btn} onClick={()=>{navigate("contacts")}} type={"button"} >
                            <div className={classes.expertise_btn_txt}>You can become our investor. Fill the form</div>
                        </CustomButton>
                    </div>
                </div>
                <div className={classes.team_info} id={"team"}>
                    <OurTeam></OurTeam>
                    <Technologies></Technologies>
                </div>
            </div>
        </>

    )
}

export default Main